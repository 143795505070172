var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "374"
    }
  }, [_c('v-img', {
    attrs: {
      "lazy-src": _vm.item.imagePlaceHolder,
      "src": _vm.item.image,
      "max-height": "150"
    }
  }), _c('v-card-title', [_vm._v(" " + _vm._s(_vm.number) + " " + _vm._s(_vm.number ? ")" : "") + " " + _vm._s(_vm.item.title) + " ")]), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.item.subtitle) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }