var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "margin": 0,
      "slotClass": "px-0",
      "loading": _vm.loading,
      "title": _vm.$t('components.partner.PartnerReportInitializeCard.empty.title'),
      "subtitle": _vm.$t('components.partner.PartnerReportInitializeCard.empty.description')
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "data-test-close-empty": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('close');
            }
          }
        }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)];
      },
      proxy: true
    }, {
      key: "card-actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "loading": _vm.loading,
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.setNextStep(_vm.PagesEnum.CHOICE);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportInitializeCard.back")) + " ")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "loading": _vm.loading,
            "outlined": "",
            "color": "info"
          },
          on: {
            "click": function click($event) {
              _vm.isConfirmDialogActive = true;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("reset")) + " ")]), _c('v-btn', {
          attrs: {
            "elevation": 0,
            "loading": _vm.loading,
            "color": "info",
            "disabled": !_vm.isValid,
            "data-test-create-report": ""
          },
          on: {
            "click": _vm.save
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportInitializeCard.create")) + " ")])];
      },
      proxy: true
    }])
  }, [_c('create-report-form', {
    key: 'create-report-form' + _vm.forceUpdateKey,
    attrs: {
      "active": {
        damage: true,
        date: true,
        contact: true,
        witness: true,
        police: true,
        insurance: true,
        leasing: true,
        accident: true,
        registration: true,
        images: true,
        body: false,
        message: false,
        vehicleHeader: true
      },
      "dto": _vm.reportDto
    },
    on: {
      "update:dto": function updateDto($event) {
        _vm.reportDto = $event;
      }
    },
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isConfirmDialogActive
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isConfirmDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isConfirmDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isConfirmDialogActive = false;
      },
      "rightClick": _vm.reset
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }