var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-logo', {
    attrs: {
      "src": _vm.$helpers.showValueWithDefault(_vm.partner.settings.logoUrl, _vm.defaultLogoUrl)
    }
  }, [_c('v-container', {
    staticClass: "contentContainer",
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "d-none d-md-flex"
  }, [_c('v-card', {
    staticClass: "smartCard",
    attrs: {
      "elevation": "19",
      "outlined": "",
      "shaped": ""
    }
  }, [_c('v-card-title', [_c('h1', {
    style: {
      color: _vm.partner.settings.color
    }
  }, [_vm._v(" " + _vm._s(_vm.$helpers.showValueWithDefault(_vm.partner.settings.cardTitle, _vm.CARD_TITLE_DEFAULT)) + " ")])]), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.$helpers.showValueWithDefault(_vm.partner.settings.cardSubtitle, _vm.CARD_SUBTITLE_DEFAULT)) + " ")]), _c('v-card-text', [_vm._v(" " + _vm._s(_vm.$helpers.showValueWithDefault(_vm.partner.settings.cardText, _vm.CARD_TEXT_DEFAULT)) + " ")])], 1)], 1), _c('v-img', {
    staticClass: "d-none d-md-flex",
    attrs: {
      "src": _vm.$helpers.showValueWithDefault(_vm.partner.settings.headerImageUrl, _vm.defaultHeaderImageUrl),
      "max-height": "400"
    }
  }), _c('v-container', {
    staticClass: "timelineContainer"
  }, [_c('FormHelpTimeline', {
    attrs: {
      "color": _vm.partner.settings.color,
      "toolbarSrc": _vm.$helpers.showValueWithDefault(_vm.partner.settings.headerImageUrl, _vm.defaultHeaderImageUrl)
    }
  })], 1), _c('v-container', {
    staticClass: "formContainer"
  }, [_c('v-form', {
    ref: "form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validate.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.emailRules,
      "type": "email",
      "label": "E-Mail *",
      "outlined": "",
      "autocomplete": "email",
      "required": "",
      "data-test-email-input": ""
    },
    model: {
      value: _vm.partnerContact.email,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerContact, "email", $$v);
      },
      expression: "partnerContact.email"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "outlined": "",
      "data-test-name-input": ""
    },
    model: {
      value: _vm.partnerContact.name,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerContact, "name", $$v);
      },
      expression: "partnerContact.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Telefon",
      "outlined": "",
      "data-test-phone-input": ""
    },
    model: {
      value: _vm.partnerContact.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerContact, "phone", $$v);
      },
      expression: "partnerContact.phone"
    }
  })], 1)], 1), _c('v-textarea', {
    attrs: {
      "label": "Anmerkung ",
      "outlined": "",
      "data-test-annotation-input": ""
    },
    model: {
      value: _vm.partnerContact.message,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerContact, "message", $$v);
      },
      expression: "partnerContact.message"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('v-file-input', {
    attrs: {
      "rules": [function (v) {
        return !!v || 'Bitte ein Bild vom Fahrzeugschein angeben.';
      }],
      "accept": "image/png, image/jpeg, image/jpg",
      "append-outer-icon": "mdi-camera",
      "label": "Fahrzeugschein *",
      "outlined": "",
      "prepend-icon": "",
      "small-chips": "",
      "data-test-fahrzeugschein-input": "",
      "show-size": ""
    },
    on: {
      "change": _vm.changeFahrzeugschein
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var text = _ref.text;
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "text-color": "white",
            "color": "success"
          }
        }, [_vm._v(" " + _vm._s(text) + " ")])];
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('v-file-input', {
    attrs: {
      "rules": [function (v) {
        return v && v.length > 0 || 'Bitte Schadensbilder angeben.';
      }, function (v) {
        return !v || !v.length || v[0].size < 10000000 || 'Bilder sollten kleiner als 10 MB sein!';
      }],
      "accept": "image/png, image/jpeg, image/jpg",
      "append-outer-icon": "mdi-camera",
      "label": "Schadensbilder *",
      "multiple": "",
      "outlined": "",
      "prepend-icon": "",
      "required": "",
      "small-chips": "",
      "data-test-schadensbild-input": "",
      "show-size": ""
    },
    on: {
      "change": _vm.changeSchadensbild
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref2) {
        var text = _ref2.text,
            index = _ref2.index;
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "text-color": "white",
            "color": "success",
            "close": ""
          },
          on: {
            "click:close": function clickClose($event) {
              return _vm.remove(index);
            }
          }
        }, [_vm._v(" " + _vm._s(text) + " ")])];
      }
    }]),
    model: {
      value: _vm.uploadedImages,
      callback: function callback($$v) {
        _vm.uploadedImages = $$v;
      },
      expression: "uploadedImages"
    }
  })], 1)], 1), _c('v-checkbox', {
    attrs: {
      "rules": [function (v) {
        return !!v || 'Bitte die Datenschutzerklärung lesen und zustimmen.';
      }],
      "data-test-privacy-checkbox": ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', [_vm._v(" Mit dem Absenden erklären Sie sich mit der Verarbeitung Ihrer personenbezogenen Daten gemäß unserer "), [_c('a', {
          attrs: {
            "href": _vm.privacyPolicyLink,
            "target": "_blank",
            "rel": "noopener"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, [_vm._v(" Datenschutzerklärung ")])], _vm._v(" einverstanden. ")], 2)];
      },
      proxy: true
    }])
  }), _c('v-row', {
    staticClass: "justify-center align-center pt-12"
  }, [_c('v-btn', {
    staticClass: "submitButton",
    attrs: {
      "disabled": !_vm.valid,
      "loading": _vm.loading,
      "color": "success",
      "rounded": "",
      "x-large": "",
      "data-test-angebot-button": ""
    },
    on: {
      "click": _vm.validate
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-send ")]), _vm._v(" Angebot anfordern ")], 1)], 1)], 1)], 1), _vm.partner.settings.displayMap ? _c('PartnerMap', {
    attrs: {
      "coordinates": _vm.partner.address.geo,
      "colour": _vm.partner.settings.color
    }
  }) : _c('v-spacer', {
    staticClass: "pb-8"
  })], 1), _vm.partner ? _c('PartnerFooter', {
    attrs: {
      "partnerProp": _vm.partner,
      "color": _vm.partner.settings.color
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }