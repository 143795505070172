var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-select', {
    attrs: {
      "loading": _vm.isProgressUpdating,
      "disabled": _vm.isProgressUpdateLoading,
      "items": _vm.items,
      "value": _vm.progress,
      "dense": "",
      "flat": "",
      "solo": ""
    },
    on: {
      "change": _vm.setProgress
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn() {
        return [_c('v-avatar', {
          staticClass: "mr-2 white--text",
          attrs: {
            "color": _vm.color(_vm.progress),
            "elevation": 0,
            "size": "14"
          }
        }), _vm._v(" " + _vm._s(_vm.$t("enums.ProgressStatusEnum.".concat(_vm.progress))) + " ")];
      },
      proxy: true
    }, {
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.$t("enums.ProgressStatusEnum.".concat(item))) + " ")];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }