var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('TopToolbarLogo', {
    attrs: {
      "src": _vm.src
    }
  }), _c('ErrorAlert'), _c('v-main', [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }