var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-img', {
    staticClass: "my-1",
    staticStyle: {
      "max-height": "52px"
    },
    attrs: {
      "contain": "",
      "src": _vm.src
    }
  }), _c('v-divider')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }